<template>
    <div class="flex flex-wrap pt-0">
        <div class="mt-5 w-1/2 pr-2">
            <label class="v-select-label">{{$t('addBookingSidebar.bookingOptions')}}</label>
            <v-select
            class="v-select-background"
            :value="bookingClone.option_id"
            @input="setBookingOption"
            :options="bookingOptionsList" 
            label="name"
            :reduce="name => name.id"
            :dir="$vs.rtl ? 'rtl' : 'ltr'" 
            />
        </div>

        <div class="mt-5 w-1/2 pr-2">
            <label class="v-select-label">{{$t('addBookingSidebar.eventType')}}</label>
            <v-select
            class="v-select-background"
            :value="bookingClone.event_id"
            @input="setEventType"
            :options="eventTypesList" 
            label="arabic_name"
            :reduce="arabic_name => arabic_name.id"
            :dir="$vs.rtl ? 'rtl' : 'ltr'" 
            />
        </div>

        <div class="w-full my-5" v-if="selectedOptionServicesList.length > 0">
            <span class="text-lg">{{ $t('addBookingSidebar.extraServices') }}</span>
            <div class="flex flex-wrap mt-2">
                <vs-checkbox v-for="service in selectedOptionServicesList" :key="`services-${service.id}`" v-model="selectedServices" :vs-value="service.id" @change="serviceSelected">{{service.name}} | {{ service.final_price }} {{$t('SAR')}}</vs-checkbox>
            </div>
        </div>

        <div v-if="bookingClone.event_type && bookingClone.event_type.guests_inputs" class="flex flex-wrap mt-5 w-full">
            <vs-input v-for="guests_input in bookingClone.event_type.guests_inputs" :key="guests_input.key" @change="fillForm" @input="guestInputChanged" :label="guests_input.name" v-model="bookingClone[guests_input.key]"  class="w-1/2 pr-2" type="number"/>
        </div>
        
        <div class="mt-5 w-full" v-if="!isTimeBasedBookingOption">
            <vs-checkbox v-model="isManyDays" name="isManyDays" class="mt-2" @change="fillForm">{{$t('addBookingSidebar.isManyDays')}}</vs-checkbox>
        </div>

        <div class="mt-3 w-full flex flex-wrap" v-if="!isTimeBasedBookingOption">
            <div class="w-1/2 pr-2">
                <small class="date-label">{{$t('addBookingSidebar.startDate')}}</small>
                <datepicker format="dd-MM-yyyy" @selected="fillForm" :language="$vs.rtl ? langAr : langEn" name="start-date" v-model="bookingClone.start_date" @closed="checkValidEndDate"></datepicker>
            </div>
            <div v-if="isManyDays" class="w-1/2 pr-2">
                <small class="date-label">{{$t('addBookingSidebar.endDate')}}</small>
                <datepicker format="dd-MM-yyyy" @selected="fillForm" :language="$vs.rtl ? langAr : langEn" name="end-date"  v-model="bookingClone.end_date" :disabledDates="disabledDatesTo" @closed="checkValidEndDate"></datepicker>
            </div>
        </div>

        <vs-textarea  :label="$t('addBookingSidebar.customerNotes')" v-model="bookingClone.customer_notes"  @change="fillForm" class="mt-5 w-full" name="customer-notes" />

    </div>
</template>

<script>
import { en, ar } from 'vuejs-datepicker/src/locale'
import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'
export default {
    components: {
        Datepicker,
        vSelect
    },
    props:{
        isEdit: {
            type: Boolean,
            default: false
        },
        booking:{
            type: Object,
            default: ()=> {}
        },
        
    },
    data() {
        return {
            langAr: ar,
            langEn: en,
            bookingClone: JSON.parse(JSON.stringify(this.booking)),
            isManyDays: false,
            isTimeBasedBookingOption: false, // if the selected BO is time based
            selectedServices: [],
        }
    },

    created() {
        this.$store.dispatch('dataList/fetchVenueRoomsList', this.currentVenueId)
        this.$store.dispatch('dataList/fetchbookingOptionsList', this.currentVenueId)
        this.$store.dispatch('dataList/fetchEventTypesList')
    },
    computed: {
        disabledDatesTo () {
            return { to: new Date(this.bookingClone.start_date) }
        },
        roomsList () {
            return [...this.$store.state.dataList.venueRoomsList]
        },
        bookingOptionsList () {
            return [...this.$store.state.dataList.bookingOptionsList];
        },
        eventTypesList () {
            return [...this.$store.state.dataList.eventTypesList]
        },
        selectedOptionServicesList () {
            return this.$store.state.dataList.selectedOptionServicesList;
        },
        currentVenueId () {
            return this.$store.getters.currentVenueId
        },
    },
    methods: {
        async guestInputChanged() {
            if(this.bookingClone.option_id) {
                let counts = {};
                if(this.bookingClone.event_type) {
                    this.bookingClone.event_type.guests_inputs.forEach(element => {
                        counts[element.key] = this.bookingClone[element.key]
                    });
                } else {
                    counts = {
                        men_count: 0,
                        women_count: 0,
                        people_count: 0,
                    }
                }
                await this.$store.dispatch('dataList/getSelectedOptionServicesList', {venue_id: this.currentVenueId, option_id: this.bookingClone.option_id, counts: counts})
            }
        },
        fillForm($event) {
            this.$emit('CustomEventInputChanged', this.bookingClone)
        },
        async setBookingOption(option) {
            this.selectedServices = [];
            if(option) {
                let counts = {};
                if(this.bookingClone.event_type) {
                    this.bookingClone.event_type.guests_inputs.forEach(element => {
                        counts[element.key] = this.bookingClone[element.key]
                    });
                } else {
                    counts = {
                        men_count: 0,
                        women_count: 0,
                        people_count: 0,
                    }
                }
                await this.$store.dispatch('dataList/getSelectedOptionServicesList', {venue_id: this.currentVenueId, option_id: option, counts: counts})
                const optionObj = this.bookingOptionsList.find(o => o.id == option);
                if(optionObj.has_specific_date) {
                    this.isTimeBasedBookingOption = true
                    this.bookingClone.start_date = optionObj.event_start_date;
                    this.bookingClone.end_date = optionObj.event_end_date;
                } else {
                    this.isTimeBasedBookingOption = false
                }
            } else {
                this.$store.commit('dataList/SET_SELECTED_OPTION_SERVICES_LIST', []);
                this.isTimeBasedBookingOption = false
            }
            this.bookingClone.option_id = option;
            this.$emit('CustomEventInputChanged', this.bookingClone)
        },
        async setEventType(event) {
            this.bookingClone.event_id = event;
            this.bookingClone.event_type = this.eventTypesList.find(o => o.id == event) // to change the guests inputs
            if(this.bookingClone.option_id) {
                let counts = {};
                if(this.bookingClone.event_type) {
                    this.bookingClone.event_type.guests_inputs.forEach(element => {
                        counts[element.key] = this.bookingClone[element.key]
                    });
                } else {
                    counts = {
                        men_count: 0,
                        women_count: 0,
                        people_count: 0,
                    }
                }
                await this.$store.dispatch('dataList/getSelectedOptionServicesList', {venue_id: this.currentVenueId, option_id: this.bookingClone.option_id, counts: counts})
            }
            this.$emit('CustomEventInputChanged', this.bookingClone)
        },
        checkValidEndDate() {
            if(new Date(this.bookingClone.start_date) > new Date(this.bookingClone.end_date) || this.bookingClone.end_date == 'Invalid date')
                this.bookingClone.end_date = this.bookingClone.start_date;
            if(!this.isManyDays)
                this.bookingClone.end_date = this.bookingClone.start_date;
        },
        serviceSelected() {
            this.$emit('servicesUpdated', this.selectedServices);
        },
        async openFromTheOutside() {
            
            if(this.bookingClone.option_id) {
                let counts = {};
                if(this.bookingClone.event_type) {
                    this.bookingClone.event_type.guests_inputs.forEach(element => {
                        counts[element.key] = this.bookingClone[element.key]
                    });
                } else {
                    counts = {
                        men_count: 0,
                        women_count: 0,
                        people_count: 0,
                    }
                }
                await this.$store.dispatch('dataList/getSelectedOptionServicesList', {venue_id: this.currentVenueId, option_id: this.bookingClone.option_id, counts: counts})
                if(this.bookingClone.receipt && this.bookingClone.receipt.booking_services) {
                    const services_ids = this.bookingClone.receipt.booking_services.map((service) => service.id);
                    this.selectedServices = services_ids;
                } else {
                    this.selectedServices = [];
                    this.$store.commit('dataList/SET_SELECTED_OPTION_SERVICES_LIST', []);
                }
            }
        }
    },
    watch: {
        booking: function(newVal) {
            const bookingClone = JSON.parse(JSON.stringify(newVal)); 
            this.bookingClone = bookingClone;
        },
    }
}
</script>

<style>

</style>