<template>
  <div>
    <vs-popup class="holamundo "  :title="isEdit ? bookingClone.booking_number : $t('addBookingSidebar.newBooking')" :active.sync="isActive" @close="closePopup">
      <div class="flex flex-wrap pt-10 justify-between" v-if="!isEdit && enableBlockBooking">
        <h5 class="mt-2">{{$t('addBookingSidebar.blockBooking')}}</h5>
        <vs-button icon="block" color="danger" @click="isBookingBlock = true; submitBookingForm()">{{ $t("block")}}</vs-button>
        <vs-divider/>
      </div>
      <div class="container w-full" v-if="bookingClone">
        <customer-information-booking
          :booking.sync="bookingClone"
          :isEdit="isEdit"
          @CustomEventInputChanged="bookingClone = $event"
        />
        <booking-information
          ref="bookingInformation"
          :booking.sync="bookingClone"
          :isEdit="isEdit"
          :propStartDate="startDate"
          :propEndDate="endDate"
          @CustomEventInputChanged="dataChangedInBookingInformation"
          @servicesUpdated="updateSelectedServices"
        />
        <receipts-component
          ref="receiptsRef"
          :receipt.sync="bookingClone.receipt"
          :isEdit="isEdit"
          :currentBooking="bookingClone"
          :selectedServicesIds="selectedServicesIds"
          @CustomEventInputChanged="updateReceiptInfo"
          @bookingCloneChanged="bookingClone = $event"
        />
        <div v-if="!isEdit">
          <div>
            <span class="text-lg">{{$t('addBookingSidebar.theNewBookingState')}}</span>
          </div>
          <div class="flex flex-wrap p-5 space-x-2">
            <vs-radio v-model="newBookingStatusId" vs-name="newBookingStatusId" :vs-value="4">{{$t('addBookingSidebar.confirmed')}}</vs-radio>
            <vs-radio v-model="newBookingStatusId" vs-name="newBookingStatusId" :vs-value="14">{{$t('addBookingSidebar.completed')}}</vs-radio>
            <vs-radio v-model="newBookingStatusId" vs-name="newBookingStatusId" :vs-value="20">{{$t('addBookingSidebar.cancelledWithRefund')}}</vs-radio>
            <vs-radio v-model="newBookingStatusId" vs-name="newBookingStatusId" :vs-value="21">{{$t('addBookingSidebar.cancelledWithoutRefund')}}</vs-radio>
          </div>
          <div v-if="newBookingStatusId == 20 || newBookingStatusId == 21">
            <vs-textarea :label="$t('addBookingSidebar.cancelationReason')" height="90px" v-model="bookingClone.cancelation_reason" class="w-full"/>
          </div>
        </div>
        <div class="my-10 pb-10">
          <vs-button v-if="!isEdit" icon="done" @click="submitBookingForm">{{ $t("Add")}}</vs-button>
          <vs-button v-else icon="edit" @click="submitBookingForm">{{ $t("edit")}}</vs-button>

        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import CustomerInformationBooking from '../../components/qosoor-components/CustomerInformationBooking'
import BookingInformation from '../../components/qosoor-components/BookingInformation.vue'
import Receipts from '../../components/qosoor-components/Receipts'
import moment from 'moment'

export default {
  components: {
    CustomerInformationBooking,
    BookingInformation,
    'receipts-component': Receipts,
  },
  props: {
    enableBlockBooking : {
      type: Boolean,
      default: false
    },
    currentBooking: {
        type: Object,
        defult: null
    },
    bookingFormPopUpActive: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    startDate: {
      default: null,
    },
    endDate: {
      default: null,
    }
    
  },
  data () {
    return {
      bookingClone: this.currentBooking ? JSON.parse(JSON.stringify(this.currentBooking)) : null,
      isBookingBlock: false,
      selectedServicesIds: [],
      newBookingStatusId: 4,
      
    }
  },
  computed: {
    isActive: {
        get() {
            return this.bookingFormPopUpActive
        },
        set(newState) {
            return newState
        }
    },
    currentVenueId () {
        return this.$store.getters.currentVenueId
    },
    eventTypesList () {
        return [...this.$store.state.dataList.eventTypesList]
    },
    bookingStatesList () {
      return [...this.$store.state.dataList.bookingStates]
    },
  },
  created () {
    this.$store.dispatch('dataList/fetchEventTypesList')
    this.$store.dispatch('dataList/fetchBookingStatesList')
  },
  methods: {
    closePopup() {
      this.removeBookingIdQueryParameter();
      this.$emit('update:bookingFormPopUpActive', false);
    },
    updateReceiptInfo(data) {
      this.bookingClone.receipt = data;
    },
    updateSelectedServices(data) {
      this.selectedServicesIds = data;
      setTimeout(() => {
        if(this.$refs.receiptsRef) this.$refs.receiptsRef.calculateReceiptFields();
      }, 200);
    },
    dataChangedInBookingInformation(data) {
      this.bookingClone = data;
      setTimeout(() => {
        if(this.$refs.receiptsRef) this.$refs.receiptsRef.setReceiptAsBookingOption();
      }, 200);
      setTimeout(() => {
        if(this.$refs.receiptsRef) this.$refs.receiptsRef.calculateReceiptFields();
      }, 700);
    },
    setupBookingDateFormats(){
      // setup the dates formats
      this.bookingClone.start_date = moment(this.bookingClone.start_date).format('yyyy-MM-DD')
      this.bookingClone.end_date = moment(this.bookingClone.end_date).format('yyyy-MM-DD')

    },
    getBookingWithoutNulls(){
      const obj = this.bookingClone;
      Object.keys(obj).forEach(function(key){
        if (obj[key] === null || key == 'source' || key == 'source_color' || key == 'source_description') {
          delete obj[key]
        }
      });

      // obj.receipt_taxable = this.bookingClone.receipt.receipt_taxable ? this.bookingClone.receipt.receipt_taxable : false;
      return obj
    },


    submitBookingForm() {
      this.setupBookingDateFormats()
      // remove the null values from the body param to avoid any issues from the backend
      const obj = this.getBookingWithoutNulls()

      if(this.isBookingBlock) {
        obj.is_booking_block = true;
        this.isBookingBlock = false;
        this.$emit('update:enableBlockBooking', false);
      }

      if(this.selectedServicesIds.length > 0) obj.services = this.selectedServicesIds

      if(!obj.cancelation_reason)
        delete obj['cancelation_reason']
      
      if(!this.isEdit) {
        obj.state_id = this.newBookingStatusId;
      }
      
      this.$vs.loading()
      // send the request
      let action = this.isEdit ? 'dataList/updateBooking' : 'dataList/addItem';

      this.$store.dispatch(action, {venueId: this.currentVenueId, request: obj, stateId: this.currentBooking.state_id, bookingId: this.currentBooking.id, receiptId: this.currentBooking.receipt.id})
        .then(e => {
          let response = Array.isArray(e)? e[0].data: e.data;
          this.$emit('updateCurrentBooking', response.data.booking);
          // if(this.$route.name == 'bookings-management') { // fetch if only bookings-management page is current
          //   this.dispatch('dataList/getUpcomingBookings', {venueId: this.currentVenueId});
          // }
          this.closePopup();
          this.selectedServicesIds = [];
          this.$vs.notify({
            color:'success',
            title: this.isEdit ? this.$t('updateDone') : this.$t('addedDone'),
            text: response.success_message
          })
        })
        .catch(function(error){ 
          this.$vs.notify({
            color:'danger',
            title: this.$t('error'),
            text: error || this.isEdit ? 'عذرًا لم نستطع انشاء حجز لوجود خطأ ما، اذا تكرر الخطأ يُرجى التواصل مع الادارة، وشكرًا' : 'عذرًا لم نستطع تعديل الحجز لوجود خطأ ما، اذا تكرر الخطأ يُرجى التواصل مع الادارة، وشكرًا'
          })
          console.error(error)
          this.error = error;
        }.bind(this))
        .finally(()=>{this.$vs.loading.close()},this);
    }
  },
  watch: {
    currentBooking: function(newVal) {
      this.bookingClone = newVal ? JSON.parse(JSON.stringify(newVal)) : null;
    },
    isActive: function(newVal) {
      if(newVal) { // only when popup is opened
        if(this.$refs.bookingInformation) this.$refs.bookingInformation.openFromTheOutside();

        if(this.currentBooking.receipt)
          if(this.currentBooking.receipt.booking_services) {
            const services_ids = this.currentBooking.receipt.booking_services.map((service) => service.id);
            this.selectedServicesIds = services_ids;
          }

        // setTimeout(() => {
        //   if(this.$refs.receiptsRef) this.$refs.receiptsRef.calculateReceiptFields();
        // }, 700);
      }
    }
  }

}
</script>

<style>

</style>