<template>

    <div class="vx-row">
      <vs-divider></vs-divider>

      <div class="vx-col w-1/2 my-3">
        <h3>{{$t('addBookingSidebar.invoices')}}</h3>
      </div>

      <div class="vx-col w-1/2">
        <vs-button class="btn-outline-primary" @click="setReceiptAsBookingOption" v-show="this.currentBooking.option_id != null">{{ $t("addBookingSidebar.setReceiptAsBookingOption")}}</vs-button>
      </div>

      <div class="vx-col md:w-1/2 w-full">
        <vs-input :label="$t('addBookingSidebar.total_price')" type="number" @keyup="fillReceiptForm" v-model="receiptClone.total_price" class="w-full mt-5" name="total_price" v-validate="'required'" autocomplete="off"/>
        <span class="text-danger"></span>
      </div>  

      
      <div class="vx-col md:w-1/2 w-full">
        <vs-input :label="$t('addBookingSidebar.agent_discount')" @keyup="fillReceiptForm" v-model="receiptClone.agent_manual_discount" class="w-full mt-5" name="agent_manual_discount"/>
        <span class="text-danger"></span>
      </div>

      <div class="vx-col md:w-1/2 w-full pt-5">
        <div class="vx-row mt-2 mx-2 required">
            <label class="v-select-label">{{$t('home.editBookingOptionForm.taxingStatus')}}</label>
        </div>
        <div class="vx-row mt-1 mx-2">
            <vs-radio class="mr-2 mt-2" v-model="receiptClone.percentage_tax" vs-name="receiptClone.percentage_tax" :vs-value="15" @change="fillReceiptForm">{{$t('home.editBookingOptionForm.taxable')}}</vs-radio>
            <vs-radio class="mr-2 mt-2" v-model="receiptClone.percentage_tax" vs-name="receiptClone.percentage_tax" :vs-value="0" @change="fillReceiptForm">{{$t('home.editBookingOptionForm.nonTaxable')}}</vs-radio>
        </div>
      </div>


      <div class="vx-col md:w-1/2 w-full">
        <vs-input disabled :label="$t('addBookingSidebar.tax')" @keyup="fillReceiptForm" v-model="receiptClone.percentage_tax" class="w-full mt-5" name="tax"/>
        <span class="text-danger"></span>
      </div>

      <div class="vx-col md:w-1/2 w-full">
        <vs-input disabled :label="$t('addBookingSidebar.totalPriceWithTax')" @keyup="fillReceiptForm" v-model="receiptClone.total_price_with_tax" class="w-full mt-5" name="totalPriceWithTax" v-validate="'required'" autocomplete="off"/>
        <span class="text-danger"></span>
      </div>

      <div class="vx-col md:w-1/2 w-full">
        <vs-input disabled :label="$t('addBookingSidebar.servicesTotalPrice')" @keyup="fillReceiptForm" v-model="receiptClone.services_total_price" class="w-full mt-5" name="deposit" v-validate="'required'" autocomplete="off"/>
        <span class="text-danger"></span>
      </div>

      <div class="vx-col md:w-1/2 w-full">
        <vs-input disabled :label="$t('addBookingSidebar.finalPrice')" @keyup="fillReceiptForm" v-model="receiptClone.final_price" class="w-full mt-5" name="totalPriceWithTax" v-validate="'required'" autocomplete="off"/>
        <span class="text-danger"></span>
      </div>

      <vs-divider/>

      <div class="vx-col w-full my-5" v-if="receiptClone.deposit">
        <span>{{ $t('addBookingSidebar.deposit_amount_note') }} <span class="text-2xl">{{ receiptClone.deposit }}</span> {{ $t('SAR') }}</span>
      </div>

      <div class="vx-col md:w-1/2 w-full">
        <vs-input type="number" :label="$t('addBookingSidebar.paid_at_place_amount')" @keyup="fillReceiptForm" v-model="receiptClone.paid_at_place_amount_value" class="w-full mt-5" name="paid_at_place_amount_value"/>
        <span class="text-danger"></span>
      </div>

      <div class="vx-col md:w-1/2 w-full">
        <vs-input disabled :label="$t('addBookingSidebar.remainingAmount')" @keyup="fillReceiptForm" v-model="receiptClone.remaining_amount" class="w-full mt-5" name="remainingAmount" v-validate="'required'" autocomplete="off"/>
        <span class="text-danger"></span>
      </div>

      <vs-textarea :label="$t('addBookingSidebar.agentPaymentNotes')" v-model="bookingClone.payment_notes_by_agent"  @change="bookingCloneChanged" class="mt-5 w-full" name="payment_notes_by_agent" />

      <vs-divider></vs-divider>


    </div>

</template>


<script>
import VSelect from 'vue-select'
import axios from 'axios'

export default {
  components: {
    VSelect
  },
  props:{
    isEdit: {
      type: Boolean,
      default: false
    },
    receipt:{
      type: Object,
      default: ()=> {}
    },
    currentBooking: {
      type: Object,
      default: ()=> {}
    },
    selectedServicesIds: {
      type: Array,
      default: ()=> []
    }
  },
  computed: {
    currentVenueId () {
      return this.$store.getters.currentVenueId
    },
    ReceiptFromBookingOption() {
      return this.$store.state.receipts.bookingOptionReceipt
    },
    selectedOptionServicesList () {
      return this.$store.state.dataList.selectedOptionServicesList;
    },
  },
  data () {
    return {
      bookingClone: JSON.parse(JSON.stringify(this.currentBooking)),
      receiptClone: JSON.parse(JSON.stringify(this.receipt)),
      selectedStateId: null,
      CurrentStateDisplay: '',
    };
  },

  methods:{
    bookingCloneChanged() {
        this.$emit('bookingCloneChanged', this.bookingClone)
    },
    fillReceiptForm(){
      this.calculateReceiptFields()
    },
    calculateReceiptFields(){
      let receipt = this.receiptClone;
      
      let total_services = this.selectedOptionServicesList.reduce((acc, obj) => {
        if (this.selectedServicesIds.includes(obj.id)) {
          acc += obj.final_price;
        }
        return acc;
      }, 0);
      
      receipt.agent_manual_discount = receipt.agent_manual_discount ? receipt.agent_manual_discount : 0;

      receipt.services_total_price = total_services;

      receipt.discounted_price = (parseFloat(receipt.total_price) - parseFloat(receipt.agent_discount))
      receipt.tax = (receipt.total_price * (receipt.percentage_tax / 100)).toFixed(2);

      receipt.paid_at_place_amount_value = parseFloat(receipt.paid_at_place_amount_value);

      receipt.total_price_with_tax = (parseFloat(receipt.discounted_price) + parseFloat(receipt.tax));
      receipt.final_price = (parseFloat(receipt.total_price_with_tax) + parseFloat(receipt.services_total_price));
      receipt.remaining_amount = Math.max(0, parseFloat(receipt.final_price) - parseFloat(receipt.paid_at_place_amount_value ? receipt.paid_at_place_amount_value : 0));

      this.$emit('CustomEventInputChanged', receipt)
    },
    setReceiptAsBookingOption() {
      let startDate = new Date(this.currentBooking.start_date).toISOString().split('T')[0];
      let endDate = new Date(this.currentBooking.end_date).toISOString().split('T')[0];
      if(this.bookingClone.option_id)
        this.$store.dispatch('receipts/getReceiptFromBookingOption', {receipt: this.receiptClone, venueId:this.currentVenueId, startDate: startDate, endDate: endDate, optionId: this.bookingClone.option_id, menCount: this.bookingClone.men_count, womenCount: this.bookingClone.women_count, peopleCount: this.bookingClone.people_count, isSeparated: this.bookingClone.is_separated})
        .finally(() => {
            this.$emit('CustomEventInputChanged', this.ReceiptFromBookingOption)
            setTimeout(() => {
              this.calculateReceiptFields();
            }, 200);
        })
    },

    exportedReceiptsClicked() {
      console.log("hello")
      return
      let startDate = new Date(this.currentBooking.start_date).toISOString().split('T')[0];
      let endDate = new Date(this.currentBooking.end_date).toISOString().split('T')[0];
      this.$store.dispatch('receipts/getReceiptFromBookingOption', {receipt: this.receipt, venueId:this.currentVenueId, startDate: startDate, endDate: endDate, optionId: this.currentBooking.option_id, menCount: this.currentBooking.men_count, womenCount: this.currentBooking.women_count, peopleCount: this.currentBooking.people_count, isSeparated: this.currentBooking.is_separated})
      .finally(() => {
          this.$emit('mutateReceiptObject', this.ReceiptFromBookingOption)
          })
    }
  },
  created(){
    // this.calculateReceiptFields()
  },
  watch: {
    currentBooking: function(newVal) {
      this.bookingClone = JSON.parse(JSON.stringify(newVal));
      // this.calculateReceiptFields()
    },
    receipt: function(newVal) {
      this.receiptClone = JSON.parse(JSON.stringify(newVal));
    },
    ReceiptFromBookingOption: function() {
      this.calculateReceiptFields()
    },
  }
}
</script>

<style lang="scss">
.vs-tooltip { // this makes the tooltip appear above the popup.
  z-index: 99999 !important;
}
</style>
