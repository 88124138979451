<template>
  <div class="flex flex-wrap pt-0">
        <vs-input v-if="isEdit" disabled :label="$t('addBookingSidebar.bookingNumber')" v-model="booking.booking_number" class="mt-5 w-1/2 pr-2" name="bookingNumber" />
        <vs-input v-if="isEdit" disabled :label="$t('addBookingSidebar.created_at')" v-model="booking.created_at" class="mt-5 w-1/2 pr-2" name="created_at" />

        <!-- customer_name -->
        <vs-input :label="$t('addBookingSidebar.customerName')" @keyup="fillForm" v-model="bookingClone.customer_name" class="mt-5 w-1/2 pr-2" data-vv-scope="step-1" name="customer_name" v-validate="'required'" autocomplete="off"/>
        <!-- <span class="text-danger text-sm" v-show="errors.has('step-1.customer_name')">{{ errors.first('step-1.customer_name') }}</span> -->
        
        <!-- NAME -->
        <vs-input :label="$t('addBookingSidebar.customerPhoneNumber')" @keyup="fillForm" v-model="bookingClone.customer_phone_number" class="mt-5 w-1/2 pr-2" data-vv-scope="step-1" name="customer_phone_number" v-validate="{required: true,regex: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im }" autocomplete="off"/>
        <!-- <span class="text-danger text-sm" v-show="errors.has('step-1.customer_phone_number')">{{ errors.first('step-1.customer_phone_number') }}</span> -->

        <vs-checkbox v-model="bookingClone.is_booking_for_business" class="mt-10 mb-5 w-full pr-2">{{$t('addBookingSidebar.isBookingForBusiness')}}</vs-checkbox>
        <div v-if="bookingClone.is_booking_for_business" class="w-full mb-5 flex flex-wrap">
            <vs-input :label="$t('addBookingSidebar.customerVatNumber')" @keyup="fillForm" v-model="bookingClone.customer_vat_number" class="w-1/2 pr-2" name="customer_vat_number" autocomplete="off"/>
            <vs-input :label="$t('addBookingSidebar.customerCommercialRegistrationNumber')" @keyup="fillForm" v-model="bookingClone.customer_commercial_registration_number" class="w-1/2 pr-2" name="customer_commercial_registration_number" autocomplete="off"/>
            <vs-input :label="$t('addBookingSidebar.customerAddress')" @keyup="fillForm" v-model="bookingClone.customer_city" class="w-1/2 pr-2 mt-2" name="customer_city"/>
        </div>
        <!-- email -->
        <!-- <vs-input :label="$t('addBookingSidebar.customerEmail')" @change="fillForm"
                v-model="booking.email" class="mt-5 w-full" data-vv-scope="step-1" name="customer_email" />
        <span class="text-danger text-sm" v-show="errors.has('step-1.customer_email')">{{ errors.first('step-1.customer_email') }}</span> -->

        <!-- Customer ID number -->
        <!-- <vs-input :label="$t('addBookingSidebar.customerIDNumer')" @change="fillForm"
                v-model="booking.customer_id_number" class="mt-5 w-full" data-vv-scope="step-1" name="customer_id" v-validate="'numeric|length:10'" />
        <span class="text-danger text-sm" v-show="errors.has('step-1.customer_id')">{{ errors.first('step-1.customer_id') }}</span> -->
    </div>
</template>

<script>
export default {
    props:{
        isEdit: {
            type: Boolean,
            default: false
        },
        booking:{
            type: Object,
            default: ()=> {}
        },
    },
    data () {
        return {
        bookingClone: JSON.parse(JSON.stringify(this.booking)),
        };
    },
    methods: {
        fillForm() {
            this.$emit('CustomEventInputChanged', this.bookingClone)
        }
    },
    watch: {
        booking: function(newVal) {
            this.bookingClone = JSON.parse(JSON.stringify(this.booking));
        }
    }
}
</script>

<style>

</style>